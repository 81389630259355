import React from "react";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Moment from "react-moment";

const TableRowData = ({ row, index, onEdit, onDelete, needFile }) => {
 

  return (
    <TableRow key={row.id} className="hover:bg-gray-100">
      {Object.keys(row)?.map((key) => (
        <TableCell key={key} className="py-2 px-4 border-b">
          {needFile ? (
            key === "url" ? (
              <img width={100} src={row.url} />
            ) : (
              row[key]
            )
          ) : (
            <>
              {key === "createdAt" ? (
                <Moment format="DD MMM YYYY, hh:mm:a">{row?.[key]}</Moment>
              ) : (
               row?.[key]
              )}
            </>
          )}
        </TableCell>
      ))}
      <TableCell className="py-2 px-4 flex gap-4 border-b">
        {!needFile && (
          <IconButton onClick={() => onEdit(index)} className="text-blue-500 w-[20px] block">
            <Edit color="primary" />
          </IconButton>
        )}
        <IconButton onClick={() => onDelete(index)} className="text-red-500 w-[20px] !ms-[5px]  block">
          <Delete color={"error"}  />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableRowData;
