import {
  Alert,
  Box,
  Container,
  Grid,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Typography,
} from "@mui/material";
import TableComponent from "../../components/Table/TableComponent";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { deleteFile, getAll } from "../../redux/slices/fileUploadSlice";
import ImageUpload from "../../components/Upload";
import OverflowHiddenPage from "../../components/OverFlowhidden";

export const DbStorage = () => {
  const [uiKits, setUiKits] = useState([{}]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [updateCodeBlocks, setUpdateCodeBlocks] = useState(0);
  const [fileType, setFileType] = useState(""); // State to track the selected file type
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [filesPerPage] = useState(8); // Files to display per page
  const dispatch = useDispatch();
  const [snack, setSnack] = useState(false);
  const [msg, setMsg] = useState("");
  const [src, setSrc] = useState("");

  useEffect(() => {
    dispatch(getAll())
      .then(unwrapResult)
      .then((data) => {
        setUiKits(data?.data);
      });
  }, [updateCodeBlocks, msg, src]);
  useEffect(() => {
    if (fileType) {
      console.log("Selected file type:", fileType);

      const filtered = uiKits.filter((item) => {
        console.log("Item key:", item.key);

        if (!item.key) return false;

        const extension = item.key.split(".").pop().toLowerCase();
        console.log("File extension:", extension);

        return extension === fileType.replace(".", "").toLowerCase();
      });

      console.log("Filtered files:", filtered);
      setFilteredFiles(filtered);
      setCurrentPage(1); // Reset to the first page when the filter changes
    } else {
      setFilteredFiles(uiKits);
      setCurrentPage(1); // Reset to the first page when no filter is selected
    }
  }, [fileType, uiKits, src]);

  // Get current files for pagination
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = filteredFiles.slice(indexOfFirstFile, indexOfLastFile);

  const onDelete = async (id) => {
    await dispatch(deleteFile({ queryParams: id }))
      .then(unwrapResult)
      .then(() => {
        setSnack(true);
        setMsg("File Deleted!");
        setUpdateCodeBlocks(updateCodeBlocks + 1);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Change page on pagination click
  };

  return (
    <OverflowHiddenPage>
      <Box component={"main"} className="overflow-auto h-[90vh]">
        <Container maxWidth={"xl"}>
          <Snackbar
            open={snack}
            onClose={() => setSnack(false)}
            autoHideDuration={5000}
          >
            <Alert
              onClose={() => setSnack(false)}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
          <div className="sticky top-[0px] bg-white w-full z-50 py-2">
            <ImageUpload
              id="icon-button-file"
              name="image"
              setSnack={setSnack}
              setMsg={setMsg}
              imgSrc={src}
              setSrc={setSrc}
              from="direct"
            />
            <div className="mt-2">
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel id="file-type-label">File Type</InputLabel>
                <Select
                  labelId="file-type-label"
                  value={fileType}
                  onChange={(e) => setFileType(e.target.value)}
                  label="File Type"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value=".zip">Zip</MenuItem>
                  <MenuItem value=".png">PNG</MenuItem>
                  <MenuItem value=".jpg">JPG</MenuItem>
                  {/* Add more options based on the available file types */}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Display the filtered files */}
          <Grid container spacing={3} className="pb-3">
            {currentFiles.length ? (
              currentFiles.map((item) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  className="relative flex justify-center items-center shadow p-2"
                  key={item.key}
                >
                  <button
                    onClick={() => onDelete(item.key.split("ds/")[1])}
                    className="absolute text-white text-[20px] p-0 font-semibold w-[30px] h-[30px] rounded-full top-[30px] right-[20px]"
                  >
                    <img src="/bin.png" alt="delete" />
                  </button>
                  <button
                    onClick={() => copyToClipboard(item.url)}
                    className="absolute text-white text-[20px] p-0 font-semibold w-[30px] h-[30px] rounded-full top-[30px] right-[60px]"
                  >
                    <img src="/copy.png" alt="copy" />
                  </button>
                  {!["jpg", "jpeg", "png", "avif", "webp", "svg"].includes(
                    item?.key?.split(".")[item?.key?.split(".").length - 1]
                  ) ? (
                    <div className="flex flex-col gap-3 justify-center items-center">
                      <img
                        className="w-[150px] h-[150]"
                        src="/file.png"
                        alt={item.key}
                      />
                      <p>{item.key}</p>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-3 justify-center items-center">
                      <img
                        className="w-full h-[200px]"
                        src={item.url}
                        alt={item.key}
                      />
                      <p style={{wordBreak:"break-word"}}>{item.key}</p>
                    </div>
                  )}
                </Grid>
              ))
            ) : (
              <Typography>No files available.</Typography>
            )}
          </Grid>

          {/* Pagination Component */}
          {filteredFiles.length > filesPerPage && (
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(filteredFiles.length / filesPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </Container>
      </Box>
    </OverflowHiddenPage>
  );
};
