// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchPC = createApiThunk({
  name: "fetch-projects-category",
  method: "GET",
  url: "/project-category/all",
});
export const fetchPCbyId = createApiThunk({
  name: "fetch-projects-categorybyid",
  method: "GET",
  url: "/project-category/find",
});
export const createProjectsCat = createApiThunk({
  name: "create-project",
  method: "POST",
  url: "/project-category",
});
export const updatePC = createApiThunk({
  name: "update-tc",
  method: "PUT",
  url: "/project-category",
});
export const deletePC = createApiThunk({
  name: "delete-tc",
  method: "DELETE",
  url: "/project-category",
});

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProjectsCat.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment, decrement } = projectSlice.actions;
export default projectSlice.reducer;
