// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchVC = createApiThunk({
  name: "fetch-videos-category",
  method: "GET",
  url: "/video-category/all",
});
export const fetchVCbyId = createApiThunk({
  name: "fetch-videos-categorybyid",
  method: "GET",
  url: "/video-category/id",
});
export const createVidCat = createApiThunk({
  name: "create-video",
  method: "POST",
  url: "/video-category",
});
export const updateVC = createApiThunk({
  name: "update-vc",
  method: "PUT",
  url: "/video-category",
});
export const deleteVC = createApiThunk({
  name: "delete-vc",
  method: "DELETE",
  url: "/video-category",
});

const videoCatSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createVidCat.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment, decrement } = videoCatSlice.actions;
export default videoCatSlice.reducer;
