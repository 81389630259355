// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  isSidebarOpen: false,
};
export const fetchCbCategories = createApiThunk({
  name: "fetch-cb-categories",
  method: "GET",
  url: "/code-blocks-category/categories", // Relative URL path
});
export const createCbCategories = createApiThunk({
  name: "create-cb-categories",
  method: "POST",
  url: "/code-blocks-category/categories", // Relative URL path
});
export const deleteCbCategories = createApiThunk({
  name: "delete-cb-categories",
  method: "DELETE",
  url: "/code-blocks-category/categories", // Relative URL path
});
export const updateCbCategories = createApiThunk({
  name: "update-cb-categories",
  method: "PATCH",
  url: "/code-blocks-category/categories", // Relative URL path
});
export const fetchCbCategorieById = createApiThunk({
  name: "fetch-cb-categories",
  method: "GET",
  url: "/code-blocks-category/categorie", // Relative URL path
});
export const fetchCb = createApiThunk({
  name: "fetch-cb",
  method: "GET",
  url: "/code-blocks/codeBlock",
});
export const fetchCbById = createApiThunk({
  name: "fetch-cb",
  method: "GET",
  url: "/code-blocks/codeBlockbyid",
});
export const createCb = createApiThunk({
  name: "create-cb",
  method: "POST",
  url: "/code-blocks/codeBlock",
});
export const updateCb = createApiThunk({
  name: "update-cb",
  method: "PATCH",
  url: "/code-blocks/codeBlock",
});
export const deleteCb = createApiThunk({
  name: "delete-cb",
  method: "DELETE",
  url: "/code-blocks/codeBlock",
});

const codeBlockSlice = createSlice({
  name: "code-block",
  initialState,
  reducers: {
    openSidebar(state) {
      state.isSidebarOpen = true;
    },
    closeSidebar(state) {
      state.isSidebarOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCbCategories.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { openSidebar, closeSidebar } = codeBlockSlice.actions;
export default codeBlockSlice.reducer;
