// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const uploadFile = createApiThunk({
  name: "upload-file",
  method: "POST",
  url: "/file/upload",
  contentType: "multipart/form-data",
});
export const deleteFile = createApiThunk({
  name: "delete-file",
  method: "DELETE",
  url: "/file/delete",
});
export const getAll = createApiThunk({
  name: "get-file",
  method: "GET",
  url: "/file/all",
});
const uploadSlice = createSlice({
  name: "file-upload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const { increment, decrement } = uploadSlice.actions;
export default uploadSlice.reducer;
