// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchIC = createApiThunk({
  name: "fetch-interviews-category",
  method: "GET",
  url: "/interview-category",
});
export const fetchICbyId = createApiThunk({
  name: "fetch-interviews-categorybyid",
  method: "GET",
  url: "/interview-category",
});
export const createIntCat = createApiThunk({
  name: "create-interview",
  method: "POST",
  url: "/interview-category/post",
});
export const updateIC = createApiThunk({
  name: "update-IC",
  method: "PUT",
  url: "/interview-category",
});
export const deleteIC = createApiThunk({
  name: "delete-IC",
  method: "DELETE",
  url: "/interview-category",
});

const intCatSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createIntCat.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

/* export const {} = intCatSlice.actions; */
export default intCatSlice.reducer;
