// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {};

export const createVideo = createApiThunk({
  name: "create-video",
  method: "POST",
  url: "/video/",
});
export const updateVideo = createApiThunk({
  name: "update-video",
  method: "PUT",
  url: "/video",
});
export const getVideos = createApiThunk({
  name: "get-videos",
  method: "GET",
  url: "/video/all-videos",
});
export const getVideoById = createApiThunk({
  name: "get-video-id",
  method: "GET",
  url: "/video/get/id",
});
export const getVideoByslug = createApiThunk({
  name: "get-video-slug",
  method: "GET",
  url: "/video/get/id",
});
export const getVideoSlugs = createApiThunk({
  name: "get-video-slug",
  method: "GET",
  url: "/video",
});
export const deleteVideo = createApiThunk({
  name: "delete-video",
  method: "DELETE",
  url: "/video",
});

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createVideo.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

/* export const {} = videoSlice.actions; */

export default videoSlice.reducer;
