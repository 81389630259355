import React, { useEffect, useState } from "react";
import {
  TextField,
  IconButton,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

const AnswerItem = ({
  item,
  index,
  handleChange,
  handleDelete,
  selectedCorrectIndex,
}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="start" mb={2}>
      <TextField
        label="Content"
        value={item.content}
        onChange={(e) => handleChange(index, "content", e.target.value)}
        fullWidth
        margin="normal"
      />
      <div className="flex gap-3">
        <FormControlLabel
          control={
            <Radio
              checked={Number(selectedCorrectIndex) === index}
              onChange={() => handleChange(index, "isCorrect", true)}
              color="primary"
            />
          }
          label="Is Correct"
        />
        <IconButton onClick={() => handleDelete(index)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Box>
  );
};
const AnswerList = ({ setFieldValue, data }) => {
  const [items, setItems] = useState(
    data || [{ content: "", isCorrect: false }]
  );
  const [selectedCorrectIndex, setSelectedCorrectIndex] = useState(
    data?.findIndex((item) => item.isCorrect === true) || null
  );
  useEffect(() => {
    setItems(data || [{ content: "", isCorrect: false }]);
    setSelectedCorrectIndex(
      data?.findIndex((item) => item.isCorrect === true) || null
    );
  }, [data]);

  console.log(data?.findIndex((item) => item.isCorrect === true));
  const handleChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;

    if (key === "isCorrect" && value === true) {
      setSelectedCorrectIndex(index);
      newItems.forEach((item, idx) => {
        if (idx !== index) item.isCorrect = false;
      });
    }

    setItems(newItems);
  };

  useEffect(() => {
    setFieldValue("answers", items);
  }, [items, setFieldValue]);

  const handleAdd = () => {
    setItems([...items, { content: "", isCorrect: false }]);
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    if (selectedCorrectIndex === index) {
      setSelectedCorrectIndex(null);
    } else if (selectedCorrectIndex > index) {
      setSelectedCorrectIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Answer List
      </Typography>
      {items?.map((item, index) => (
        <AnswerItem
          key={index}
          item={item}
          index={index}
          handleChange={handleChange}
          handleDelete={handleDelete}
          selectedCorrectIndex={selectedCorrectIndex}
        />
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAdd}
        startIcon={<AddIcon />}
      >
        Add Answer
      </Button>
    </Box>
  );
};

export default AnswerList;
