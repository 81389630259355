import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Box component={"section"}>
      <main className="flex items-center justify-center w-full min-h-screen py-8 text-gray-900 page md:py-16 ">
        <div className="relative flex flex-col items-center w-full gap-8 px-8 md:px-18 xl:px-40 md:gap-16">
          <h1 className="text-4xl md:text-[170px] w-full select-none  text-center font-black  text-black">
            404
          </h1>
          <p className="text-3xl font-bold mt-10 capitalize">
            You have discovered a secret place
          </p>
          <p className="text-2xl font-medium break-words text-dull">
            Unfortunately, this is only a 404 page. You may have mistyped the
            address, or the page has been moved to another URL.
          </p>
          <div className="flex flex-col justify-between w-full gap-8 md:flex-row md:gap-32 xl:px-16">
            <Link
              to="#"
              onClick={() => navigate(-1)}
              className="flex items-center justify-center w-full gap-4 p-3 font-semibold capitalize border-2 border-black rounded shadow-lg md:w-fit bg-black hover:bg-white text-white hover:text-black cp md:px-6 md:py-2  focus:outline-none hover:scale-105 active:scale-90 hover:shadow-xl "
            >
              Go back to Previous Page
            </Link>
            <Link
              to="/"
              className="rounded flex w-full md:w-fit group items-center gap-4 justify-center border-2 border-black bg-black font-semibold hover:bg-white p-3 md:px-6 md:py-2 capitalize focus:outline-none text-white hover:text-black hover:scale-105 cp active:scale-90 shadow-lg hover:shadow-xl "
            >
              Go back to Home Page
            </Link>
          </div>
        </div>
      </main>
    </Box>
  );
}

export default NotFound;
