// reducers/index.js
import { combineReducers } from "redux";
import codeBlockSlice from "./slices/codeBlockSlice";
import fileUploadSlice from "./slices/fileUploadSlice";
import tutorialsSlice from "./slices/tutorialsSlice";
import docCatSlice from "./slices/docCatSlice";
import documentationSlice from "./slices/documentationSlice";
import userSlice from "./slices/userSlice";
import videoCatSlice from "./slices/videoCatSlice";
import interCatSlice from "./slices/interCatSlice";

const rootReducer = combineReducers({
  codeBlock: codeBlockSlice,
  upload: fileUploadSlice,
  tutorial: tutorialsSlice,
  docCat: docCatSlice,
  doc: documentationSlice,
  user: userSlice,
  videoCat: videoCatSlice,
  intCat: interCatSlice,
  // Add other reducers here
});

export default rootReducer;
