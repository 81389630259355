// CustomSnackbar.js
import React from "react";
import { Snackbar, Alert } from "@mui/material";

const CustomSnackbar = ({
  open,
  onClose,
  message,
  severity = "success",
  autoHideDuration = 5000,
}) => {
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={autoHideDuration}>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
