// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchD = createApiThunk({
  name: "fetch-docs",
  method: "GET",
  url: "/docs",
});
export const fetchDbyId = createApiThunk({
  name: "fetch-docs",
  method: "GET",
  url: "/docs/doc",
});
export const fetchDbyCatSlug = createApiThunk({
  name: "fetch-docs",
  method: "GET",
  url: "/docs/doc/by",
});
export const createdoc = createApiThunk({
  name: "create-doc",
  method: "POST",
  url: "/docs",
});
export const updateD = createApiThunk({
  name: "update-doc",
  method: "PUT",
  url: "/docs",
});
export const deleteD = createApiThunk({
  name: "delete-doc",
  method: "DELETE",
  url: "/docs",
});

const docSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createdoc.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment, decrement } = docSlice.actions;
export default docSlice.reducer;
