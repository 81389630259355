// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchDC = createApiThunk({
  name: "fetch-docs-category",
  method: "GET",
  url: "/doc-category/all",
});
export const fetchDCbyId = createApiThunk({
  name: "fetch-docs-categorybyid",
  method: "GET",
  url: "/doc-category/single",
});
export const createDocCat = createApiThunk({
  name: "create-doc",
  method: "POST",
  url: "/doc-category",
});
export const updateDC = createApiThunk({
  name: "update-tc",
  method: "PUT",
  url: "/doc-category",
});
export const deleteDC = createApiThunk({
  name: "delete-tc",
  method: "DELETE",
  url: "/doc-category",
});

const docCatSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createDocCat.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment, decrement } = docCatSlice.actions;
export default docCatSlice.reducer;
