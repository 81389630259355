// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {
  value: 0,
};

export const fetchT = createApiThunk({
  name: "fetch-tutorials",
  method: "GET",
  url: "/tutorial",
});

export const fetchTbyId = createApiThunk({
  name: "fetch-tutorials",
  method: "GET",
  url: "/tutorial",
});
export const createTutorial = createApiThunk({
  name: "create-tutorial",
  method: "POST",
  url: "/tutorial",
});
export const updateT = createApiThunk({
  name: "update-tutorial",
  method: "PUT",
  url: "/tutorial",
});
export const deleteT = createApiThunk({
  name: "delete-tutorial",
  method: "DELETE",
  url: "/tutorial",
});

const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTutorial.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { increment, decrement } = tutorialSlice.actions;
export default tutorialSlice.reducer;
