// reducers/exampleSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApiThunk } from "../apiUtils";

const initialState = {};

export const createinterview = createApiThunk({
  name: "create-interview",
  method: "POST",
  url: "/interview/questions",
});
export const updateinterview = createApiThunk({
  name: "update-interview",
  method: "PUT",
  url: "/interview/questions",
});
export const getinterviews = createApiThunk({
  name: "get-interviews",
  method: "GET",
  url: "/interview/questions",
});
export const getinterviewById = createApiThunk({
  name: "get-interview-id",
  method: "GET",
  url: "/interview/questions",
});
export const getinterviewBySlug = createApiThunk({
  name: "get-interview-slug",
  method: "GET",
  url: "/interview/questions/slug",
});

export const getinterviewSlugs = createApiThunk({
  name: "get-interview-slug",
  method: "GET",
  url: "/interview",
});
export const deleteinterview = createApiThunk({
  name: "delete-interview",
  method: "DELETE",
  url: "/interview/questions",
});

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createinterview.pending, (state, action) => {
      state.isLoading = false;
    });
  },
});

/* export const {} = interviewSlice.actions; */

export default interviewSlice.reducer;
